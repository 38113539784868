import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["action"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardLayout = _resolveComponent("DashboardLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardLayout),
    _createElementVNode("form", {
      ref: "form",
      id: "_gotoTeg",
      action: _ctx.url,
      method: "get"
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "hidden",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hostname) = $event)),
        name: "source"
      }, null, 512), [
        [_vModelText, _ctx.hostname]
      ]),
      _createElementVNode("a", {
        href: "#",
        id: "click",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
        type: "submit"
      })
    ], 8, _hoisted_1)
  ], 64))
}